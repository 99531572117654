<template>
<div>
     <div class="mb-2 alert alert-warning">
        <div class="py-2">
            <span class="me-4">Your email address is not verified. <a :href="absoluteUrl(`/verify/email?email=${activeUser.email}`)" target="_blank" class="alert-link"> Please click to verify now!</a></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:"user-email-verification",
    computed: {
        activeUser() {
           return this.$store.state.AppActiveUser;
        },
    },
}
</script>
